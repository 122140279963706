/* eslint-disable no-extra-boolean-cast */
import { Box, Button, FormGroup, LinearProgress } from "@mui/material";
import React, {useState, useMemo } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Swapper } from "./Swapper";

import { useConnection } from "../contexts";

import { useWallet } from "@solana/wallet-adapter-react";
import * as anchor from "@project-serum/anchor";
import { getOwnedNFTMints, searchEntanglements } from "../utils/entangler";

export const Wizard = () => {
  const connection = useConnection();
  const wallet = useWallet();

  const [entanglements, setEntanglements] = React.useState<Array<object>>([]);
  const [myNFTs, setMyNFTs] = React.useState<Array<object>>([]);
  const [loading, setLoading] = useState(false);
  const sortedItems = [];

  const authority = process.env.REACT_APP_WHITELISTED_AUTHORITY!;

  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;
  }, [wallet]);

  const handleSubmit = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (!anchorWallet) {
      return;
    }
    setLoading(true);
    setEntanglements([]);
    const res = await getOwnedNFTMints(anchorWallet, connection);
    const walletNFTMints = res.map((token) => token.info.mint);
    setMyNFTs(walletNFTMints);
    const allEntanglementsMap: any[] = [];
    for (let i = 0; i < walletNFTMints.length; i++) {
      const { entanglements, metadata } = await searchEntanglements(
        anchorWallet,
        connection,
        walletNFTMints[i],
        authority
      );
      allEntanglementsMap.push({
        mint: walletNFTMints[i],
        entanglements,
        metadata,
      });
    }
    setEntanglements([...(await allEntanglementsMap)]);
    setLoading(false);
  };

  

  return (
    <React.Fragment>
    
      <Typography variant="h4" color="text.primary" gutterBottom>
        Swap to Join Official Kaiju: Reborn
      </Typography>

      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <FormGroup>
          <Button
            disabled={!anchorWallet || loading || !!!authority}
            variant="contained"
            onClick={async (e) => await handleSubmit(e)}
            endIcon={<SearchIcon />}
          >
            Search Eligible Swaps
          </Button>
        </FormGroup>
      </Box>
      <Box sx={{ maxWidth: "md", display: "block", margin: "3rem auto" }}>
        {loading && <LinearProgress />}

        {
          //@ts-ignore
          entanglements.map((e: any) => {
            return (
              <Card sx={{ minWidth: 275, boxShadow: 3, mb: 3 }} key={e.mint}>
                  {e.entanglements.length > 0 && (
                    <React.Fragment>
                      {e.entanglements.map((m: any) => {
                       
                       
                        console.log(m.mintA.toString())
                        let mintA = m.mintA.toString();
                        //@ts-ignore
                        if(sortedItems.includes(mintA)){
                          // console.log("duplicate")
                          return null;
                        } else {
                          //@ts-ignore
                          sortedItems.push(mintA)

                        return(
                        <div key={m.mintA.toString()}>
                <CardContent>
                          <Typography
                            sx={{ fontSize: 19 }}
                            component="div"
                            gutterBottom
                          >
                            <strong>{e.mint}</strong>
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: myNFTs.find(
                                (d: any) => d === m.mintA.toBase58()
                              )
                                ? "row"
                                : "row-reverse",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                          >
                          <div style={{width: "100%"}}>
                            <img
                              alt="Mint A Icon"
                              style={{ width: "100%", height: "auto" }}
                              src={
                                e.metadata.find((d) => d.mint.equals(m.mintA))
                                  .imageUrl
                              }
                            />
                            <Box component="div" sx={{ textOverflow: 'ellipsis' }}>
                              <h3 style={{color: "white"}}>KaijuVerse</h3>
                            </Box>
                            </div>
                           <ArrowRightAltIcon sx={{ fontSize: 40 }}/>
                           <div style={{width: "100%"}}>
                            <img
                              alt="Mint B Icon"
                              style={{ width: "100%", height: "auto" }}
                              src={
                                e.metadata.find((d) => d.mint.equals(m.mintB))
                                  .imageUrl
                              }
                            />
                            <Box component="div" sx={{ textOverflow: 'ellipsis' }}>
                              <h3 style={{color: "white"}}>Official Kaiju: Reborn</h3>
                            </Box>
                          </div>
                          </div>
                          <Swapper
                            mintA={m.mintA.toString()}
                            mintB={m.mintB.toString()}
                          />
                </CardContent>
                        </div>
                      )
                        }}
                      )}
                    </React.Fragment>
                  )}
              </Card>
            );
          })
        }
      </Box>
    </React.Fragment>
  );
};
