import { Box, Stack } from "@mui/material";
import { Settings } from "../Settings";

export const Header = ({ narrow }: { narrow: boolean }) => {
  return (
    <Box
      sx={{
        display: "flex",
        overflow: "auto",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginLeft: "36px",
        }}
      >
        <img
          style={{ width: "100%", height: "auto" }}
          src="/reborn.png"
          alt="Logo"
        />
      </Stack>

      <Box sx={{ flexGrow: 1, minWidth: "36px" }}></Box>
      <Settings narrow={narrow} />
    </Box>
  );
};
